import React from 'react'
import type { SVGProps } from 'react'

export function CircleMinus(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<title>Menos</title>
			<circle cx='23' cy='23' r='23' fill='#263A2D' />
			<path d='M18.6753 24.5719V21.3477H27.7789V24.5719H18.6753Z' fill='white' />
		</svg>
	)
}
