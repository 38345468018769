import * as React from 'react'
import type { SVGProps } from 'react'

const Stars5 = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={58} height={10} fill='none' {...props}>
		<title>5 estrelas</title>
		<path
			fill='#F7CD37'
			d='M4.641.727a.4.4 0 0 1 .718 0l1.017 2.062a.4.4 0 0 0 .302.219l2.275.33a.4.4 0 0 1 .222.683L7.528 5.626a.4.4 0 0 0-.115.354l.389 2.266a.4.4 0 0 1-.58.422l-2.036-1.07a.4.4 0 0 0-.372 0l-2.036 1.07a.4.4 0 0 1-.58-.422l.389-2.266a.4.4 0 0 0-.115-.354L.825 4.02a.4.4 0 0 1 .222-.683l2.275-.33a.4.4 0 0 0 .302-.22L4.64.728ZM16.641.727a.4.4 0 0 1 .718 0l1.017 2.062a.4.4 0 0 0 .302.219l2.275.33a.4.4 0 0 1 .222.683l-1.647 1.605a.4.4 0 0 0-.115.354l.389 2.266a.4.4 0 0 1-.58.422l-2.036-1.07a.4.4 0 0 0-.372 0l-2.036 1.07a.4.4 0 0 1-.58-.422l.389-2.266a.4.4 0 0 0-.115-.354L12.825 4.02a.4.4 0 0 1 .222-.683l2.275-.33a.4.4 0 0 0 .302-.22L16.64.728ZM28.641.727a.4.4 0 0 1 .718 0l1.017 2.062a.4.4 0 0 0 .302.219l2.275.33a.4.4 0 0 1 .222.683l-1.647 1.605a.4.4 0 0 0-.115.354l.389 2.266a.4.4 0 0 1-.58.422l-2.036-1.07a.4.4 0 0 0-.372 0l-2.035 1.07a.4.4 0 0 1-.58-.422l.388-2.266a.4.4 0 0 0-.115-.354L24.825 4.02a.4.4 0 0 1 .222-.683l2.275-.33a.4.4 0 0 0 .302-.22L28.64.728ZM40.641.727a.4.4 0 0 1 .718 0l1.017 2.062a.4.4 0 0 0 .302.219l2.275.33a.4.4 0 0 1 .222.683l-1.647 1.605a.4.4 0 0 0-.115.354l.389 2.266a.4.4 0 0 1-.58.422l-2.036-1.07a.4.4 0 0 0-.372 0l-2.035 1.07a.4.4 0 0 1-.58-.422l.388-2.266a.4.4 0 0 0-.115-.354L36.825 4.02a.4.4 0 0 1 .222-.683l2.275-.33a.4.4 0 0 0 .302-.22L40.64.728ZM52.641.727a.4.4 0 0 1 .718 0l1.017 2.062a.4.4 0 0 0 .302.219l2.275.33a.4.4 0 0 1 .222.683l-1.647 1.605a.4.4 0 0 0-.115.354l.389 2.266a.4.4 0 0 1-.58.422l-2.036-1.07a.4.4 0 0 0-.372 0l-2.035 1.07a.4.4 0 0 1-.58-.422l.388-2.266a.4.4 0 0 0-.115-.354L48.825 4.02a.4.4 0 0 1 .222-.683l2.275-.33a.4.4 0 0 0 .302-.22L52.64.728Z'
		/>
	</svg>
)
export default Stars5
