import React from 'react'
import type { SVGProps } from 'react'

export const ArrowBlogIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<title>Abrir Post</title>
		<path
			d='M24.8171 9.40039L10.5171 22.6004'
			stroke={props.stroke || '#263A2D'}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M14.7783 9.33398L24.8172 9.39932L24.8894 18.6673'
			stroke={props.stroke || '#263A2D'}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)
