import React from 'react'
import type { SVGProps } from 'react'

export function CirclePlus(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<title>Mais</title>
			<circle cx='23' cy='23' r='23' fill='#F1F1F1' />
			<path d='M15 24.1668V21.322H30.8365V24.1668H15ZM21.2271 30.2675V15H24.5461V30.2675H21.2271Z' fill='#263A2D' />
		</svg>
	)
}
